import { useContext, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import Container from "react-bootstrap/Container";
import AppointmentsFormContext from "../../../context/appointments-form";
import ProfileCard from "./ProfileCard";
import PatientForm from "./PatientForm";
import Button from "../../ui/Button";
import Toast from "../../ui/Toast";
import { rxOpdApi } from "../../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../../utils/api/apiEndPoints";
import ModeContext from "../../../context/mode-context";
import EditPatientprofile from "./EditPatientprofile";
import HospitalsContext from "../../../context/hospitals-context";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";

import { styled, Switch } from "@mui/material";
import { RemoveCircle } from "@mui/icons-material";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

function SelectProfile(props) {
  const { currentHospital } = useContext(HospitalsContext);
  const [profileFoam, setProfileForm] = useState([]);
  const [editid, setEditid] = useState("");
  const [editIdChanged, setEditIdChanged] = useState(false);
  const { mode } = useContext(ModeContext);

  const fetchPatientdetial = async () => {
    const userKeys = localStorage.getItem("usr_keys");
    const userModeKey = JSON.parse(userKeys)[mode];
    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];

    rxOpdApi.setAuthHeaders(key, secret);
    const res = await rxOpdApi.get(
      `${RX_OPD_ENDPOINTS.HOSPITAL.OPD.PATIENT_INFO}/${currentHospital.hos_id}/${editid}`
    );
    if (res) {
      setProfileForm(res.data);
    }
  };
  useEffect(() => {
    fetchPatientdetial();
    setEditIdChanged(false);
  }, [editIdChanged, editid, currentHospital.hos_id]);

  const { setChangeStep, setJumpToStep } = props;
  // const StyledTableCell = styled(TableCell)(({ theme }) => ({
  //   [`&.${tableCellClasses.head}`]: {
  //     backgroundColor: "#00b0f0",
  //     fontSize: 13,
  //     color: theme.palette.common.white,
  //     padding: "12px",
  //   },
  //   [`&.${tableCellClasses.body}`]: {
  //     fontSize: 12,
  //     padding: "10px",
  //     textAlign: "start",
  //   },
  // }));

  // const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
  // }));
  const {
    profiles,
    setSelectedProfile,
    fetchPatientProfiles,
    newProfileAdded,
    setNewProfileAdded,
  } = useContext(AppointmentsFormContext);

  const [createPatientForm, setCreatePatientForm] = useState(false);
  const [editPatientForm, setEditPatientForm] = useState(false);
  const [loadingProfiles, setLoadingProfiles] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [reportedProblem, setReportedProblem] = useState("");
  sessionStorage.setItem("reportedproblem", reportedProblem);

  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [profilename, setProfilename] = useState("");
  const formikProps = useFormikContext();
  const [newfile, setNewfile] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [casefileid, setCasefileid] = useState("");
  sessionStorage.setItem("casefileid", casefileid);

  useEffect(() => {
    const loadPatientProfiles = async () => {
      setLoadingProfiles(true);
      try {
        const res = await fetchPatientProfiles(
          formikProps.values.patientDetails.contactNo
        );

        if (!res) {
          throw new Error("Something went wrong. Please try again later.");
        }
      } catch (error) {
        setShowToast(true);
        setToastType("error");
        setToastMessage(error.message);
      } finally {
        setLoadingProfiles(false);
        setNewProfileAdded(false);
      }
    };

    loadPatientProfiles();
  }, [newProfileAdded]);

  const handleSelectProfile = async (profile) => {
    setOpenModal(true);
    setSelectedProfile(profile);
    setProfilename(profile);
    setSelectedProfileId(profile.profile_id);
    // setSwitchState(false); // Ensure the switch is off
    await fetchCases(profile.profile_id); // Fetch cases for the selected profile
  };

  const handlemandal = () => {
    if (selectedRow) {
      setCasefileid(selectedRow?.case_file_id);
      // Here you can add your logic to handle the selected case ID
      // For example, you might want to call an API or update some state
    } else {
      console.log("No row selected");
    }
    setChangeStep(true);
    setJumpToStep(3);
  };

  const handleChange = (event) => {
    setReportedProblem(event.target.value);
    console.log("consoleing", event.target.value);
  };

  const handleAddNewPatient = () => setCreatePatientForm(true);

  const handleEditClick = (id, e) => {
    e.stopPropagation();
    setEditid(id);
    setEditIdChanged(true);
    setEditPatientForm(true);
  };

  const fetchCases = async (profileId) => {
    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      rxOpdApi.setAuthHeaders(key, secret);
      const res = await rxOpdApi.get(
        `${RX_OPD_ENDPOINTS?.HOSPITAL?.OPD?.NEW_CASES}/${currentHospital.hos_id}/${profileId}`
      );
      if (res && res.data && res.data.cases) {
        setNewfile(res.data.cases);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSwitchChange = async (event) => {
    const newSwitchState = event.target.checked;
    setSwitchState(newSwitchState);

    if (!newSwitchState && selectedProfileId) {
      await fetchCases(selectedProfileId);
    }
  };

  return (
    <>
      <div>
        {!loadingProfiles ? (
          <Container className="w-100" style={{ marginTop: "-14px" }}>
            {profiles.map((profile) => (
              <ProfileCard
                key={profile.profile_id}
                name={profile.name}
                gender={profile.gender}
                age={profile.age}
                email={profile.email}
                profile_id={profile.profile_id}
                profileType={profile.profile_type}
                onClick={() => handleSelectProfile(profile)}
                oneditClick={(e) => handleEditClick(profile.profile_id, e)}
              />
            ))}

            <Button
              className=""
              style={{ width: "105%", margin: "0 -12px" }}
              onClick={handleAddNewPatient}
            >
              Add New
            </Button>
          </Container>
        ) : (
          <div className="w-100 text-center">
            <Spinner
              as="span"
              animation="border"
              size="md"
              role="status"
              aria-hidden="true"
              className="mx-auto"
            />
          </div>
        )}

        {createPatientForm && (
          <PatientForm
            addToExistingProfile={true}
            show={createPatientForm}
            onHide={() => setCreatePatientForm(false)}
            setChangeStep={setChangeStep}
            setJumpToStep={setJumpToStep}
          />
        )}
        {editPatientForm && (
          <EditPatientprofile
            editid={editid}
            data={profileFoam}
            addToExistingProfile={true}
            show={editPatientForm}
            onHide={() => setEditPatientForm(false)}
            setChangeStep={setChangeStep}
            setJumpToStep={setJumpToStep}
          />
        )}
      </div>

      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}

      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        size="lg"
        centered
        style={{ marginTop: "-10%" }}
      >
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>Create a new case or select existing</Modal.Title>
        </Modal.Header>
        <span style={{ marginLeft: "1rem", fontWeight: "bold" }}>
          {profilename?.name}
          {profilename?.gender &&
            profilename?.age &&
            ` (${profilename.gender}, ${profilename.age})`}
        </span>{" "}
        <Modal.Body className="bg-light">
          <span style={{ marginLeft: "1rem", fontWeight: "bold" }}>
            <Switch checked={switchState} onChange={handleSwitchChange} />
            Create New Case ID
          </span>
          {switchState && (
            <Form.Group className="mb-4">
              <Form.Label>Reported problems</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={reportedProblem}
                onChange={handleChange}
                required
                placeholder="Describe the reported problems here..."
                className="shadow-sm"
              />
            </Form.Group>
          )}

          {!switchState && (
            <div
              style={{ maxHeight: "300px", overflowY: "auto" }}
              className="mt-4"
            >
              <Table striped bordered hover responsive className="shadow-sm">
                <thead className="bg-info text-white">
                  <tr>
                    <th>Date</th>
                    <th>Case ID</th>
                    <th>Reported Problem</th>
                  </tr>
                </thead>
                <tbody>
                  {newfile &&
                    Array.isArray(newfile) &&
                    newfile.map((row, index) => (
                      <tr
                        key={index}
                        onClick={() => setSelectedRow(row)}
                        style={{
                          backgroundColor:
                            selectedRow === row ? "#e3f2fd" : "inherit",
                          cursor: "pointer",
                        }}
                      >
                        <td>{row?.date || ""}</td>
                        <td>{row?.case_id || ""}</td>
                        <td>{row?.reported_problem || ""}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handlemandal}
            disabled={!selectedRow && !switchState}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectProfile;
