import { useContext, useState, useEffect } from "react";
import { useFormikContext } from "formik";
import Spinner from "react-bootstrap/Spinner";
import AppointmentsFormContext from "../../../context/appointments-form";
import NoPatientProfile from "./NoPatientProfile";
import PatientForm from "./PatientForm";
import FormControl from "../../form/FormControl";
import Button from "../../ui/Button";
import Toast from "../../ui/Toast";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { RX_OPD_ENDPOINTS } from "../../../utils/api/apiEndPoints";
import { rxOpdApi } from "../../../utils/api/api";
import ModeContext from "../../../context/mode-context";
import HospitalsContext from "../../../context/hospitals-context";
import { FormHelperText } from "@material-ui/core";

function PatientDetails(props) {
  const { setChangeStep, setJumpToStep } = props;
  const { setProfiles, fetchPatientProfiles } = useContext(
    AppointmentsFormContext
  );
  const mode = useContext(ModeContext);
  const { currentHospital } = useContext(HospitalsContext);
  const formikProps = useFormikContext();

  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [loadingPatientDetails, setLoadingPatientDetails] = useState(false);
  const [showNoPatientsModal, setShowNoPatientsModal] = useState(false);
  const [noPatientProfileMsg, setNoPatientProfileMsg] = useState(null);
  const [createPatientForm, setCreatePatientForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isWhatsAppOTP, setIsWhatsAppOTP] = useState(true);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [timer, setTimer] = useState(0);
const contactNo = sessionStorage.getItem('contactNo');
const [otpError, setOtpError] = useState(""); // State to store OTP error message


  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleSendCode = async () => {
    setIsSendingCode(true);

    try {
      const response = await rxOpdApi.post(
        `${RX_OPD_ENDPOINTS.HOSPITAL.OPD.PATIENT_OTP}/${currentHospital.hos_id}`,
        {
          phone: `+91` +  contactNo,
          wa_otp: isWhatsAppOTP,
        }
      );

      if (response?.data) {
        setShowOtpInput(true);
        // setShowToast(true);
        setToastType("success");
        setToastMessage(response?.data?.message);
        setTimer(120); // Set 2-minute timer
      }
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(error.message || "Failed to send OTP. Please try again.");
    } finally {
      setIsSendingCode(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp || otp.length !== 6) {
      setShowToast(true);
      setToastType("error");
      setToastMessage("Please enter a valid 6-digit OTP");
      return;
    }

    setIsVerifying(true);

    try {
      const response = await rxOpdApi.post(
        `${RX_OPD_ENDPOINTS.HOSPITAL.OPD.OTP_VERIFY}/${currentHospital.hos_id}`,
        {
          phone: `+91` +  contactNo,
          otp: otp,
        }
      );

      if (response?.data) {
        setCreatePatientForm(true);
        handleCloseModal();
        // setShowToast(true);
        // setToastType("success");
        // setToastMessage();
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setShowToast(false);
      setToastType("error");
      // setToastMessage(
      //   error.message || "Failed to verify OTP. Please try again."
      // );
      setOtpError("User OTP verification failed. Please try again.");

    } finally {
      setIsVerifying(false);
    }
  };

  const handleFetchDetails = async () => {
    try {
      const errors = await formikProps.setFieldTouched(
        "patientDetails.contactNo"
      );

      if (
        "patientDetails" in errors &&
        Object.keys(errors.patientDetails).length !== 0
      ) {
        return;
      }

      setLoadingPatientDetails(true);
      const contactNo = formikProps.values.patientDetails.contactNo;
      const data = await fetchPatientProfiles(contactNo);

      if (!data) {
        throw new Error("Something went wrong. Please try again later.");
      }

      if (data.message) {
        setShowNoPatientsModal(true);
        setNoPatientProfileMsg(data.message);
      } else {
        setProfiles(data.profiles);
        setChangeStep(true);
        setJumpToStep(2);
      }
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(error.message);
    } finally {
      setLoadingPatientDetails(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPhoneNumber("");
    setOtp("");
    setShowOtpInput(false);
    setIsWhatsAppOTP(false);
    setTimer(0);
  };

  return (
    <>
      <div className="text-center">
        <FormControl
          label="Contact No."
          type="tel"
          name="patientDetails.contactNo"
          labelColClass="col-12 col-md-4"
          className="mx-0"
        />

        <Button
          className="mt-4 w-100"
          onClick={handleFetchDetails}
          disabled={loadingPatientDetails}
        >
          {loadingPatientDetails ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mx-3"
            />
          ) : (
            "Fetch Details"
          )}
        </Button>
      </div>

      {showNoPatientsModal && (
        <NoPatientProfile
          show={showNoPatientsModal}
          onHide={() => setShowNoPatientsModal(false)}
          message={noPatientProfileMsg}
          // onProceed={() => {
          //   setShowNoPatientsModal(false);
          //   setShowModal(true);
          onProceed={() => {
            setShowNoPatientsModal(false);
            setCreatePatientForm(true);
          }}
          // }}
        />
      )}
      

      {createPatientForm && (
        <PatientForm
          addToExistingProfile={false}
          show={createPatientForm}
          onHide={() => setCreatePatientForm(false)}
          setChangeStep={setChangeStep}
          setJumpToStep={setJumpToStep}
          profileType="self"
        />
      )}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        className="h-75"
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Mobile No.</Modal.Title>
        </Modal.Header>

        <div 
          className="w-100 text-center py-3 mb-4"
          style={{ 
            backgroundColor: '#007bff',
            color: 'white',
            fontSize: '16px',
            fontWeight: '500',
            textAlign: "center"
          }}
        >
          Verify mobile no. to continue patient registration
        </div>
        <Modal.Body className="py-4">
          <Form>
            <Form.Group className="mb-3" controlId="formPhoneNumber">
              <div className="input-group">
                <span className="input-group-text">+91</span>
                <Form.Control
                  type="tel"
                  placeholder="Enter phone number"
                  value={contactNo}
                  onChange={(e) =>
                    setPhoneNumber(
                      e.target.value.replace(/\D/g, "").slice(0, 10)
                    )
                  }
                  maxLength={10}
                />
              </div>
            </Form.Group>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{ color: "gray", fontWeight: "bold", marginTop: "10px" }}
              >
                Send OTP via WhatsApp
              </div>
              <Form.Check
                type="switch"
                id={`reassign-appointment-switch`}
                checked={isWhatsAppOTP}
                onChange={(e) => setIsWhatsAppOTP(e.target.checked)}
                className="custom-switch"
                style={{ marginTop: "1rem" }}
              />
            </div>
            <div className="text-end">
            <Button
  variant="primary"
  onClick={handleSendCode}
  disabled={(showOtpInput && timer > 0) || isSendingCode}
  style={{ marginTop: "1rem" }}
>
  {isSendingCode ? "Sending..." : timer > 0 ? `Resend OTP in ${formatTime(timer)}` : showOtpInput ? "Resend Code" : "Send Code"}
</Button>
            </div>

            {toastMessage && (
              <FormHelperText  style={{textAlign: "center", color:"green", fontWeight: "bold", fontSize:"14px" }}>
                {toastMessage}
              </FormHelperText>
            )}

            {showOtpInput && (
              <Form.Group className="mt-4" controlId="formOtp">
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) =>
                    setOtp(e.target.value.replace(/\D/g, "").slice(0, 6))
                  }
                  maxLength={6}
                />
                                       {otpError && (
              <FormHelperText error sx={{ textAlign: "center", mt: 1 }}>
                {otpError}
              </FormHelperText>
            )}

              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center mt-4">
            {showOtpInput && (
              <Button
                variant="primary"
                onClick={handleVerifyOtp}
                disabled={!otp || otp.length !== 6 || isVerifying}
              >
                {isVerifying ? "Verifying..." : "Verify & Next"}
              </Button>
            )}
          </div>
        </Modal.Footer>
        {showOtpInput && (
          <p
            style={{
              color: "gray",
              fontWeight: "bold",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            (Once mobile no. is verified, you will be taken to the patient
            registration form)
          </p>
        )}
      </Modal>

      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
}

export default PatientDetails;
