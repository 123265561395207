import { useContext, useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";

import ModeContext from "../../context/mode-context";
import FormControl from "../form/FormControl";
import InputErrorMessage from "../kyc/InputErrorMessage";
import accreditationBodyOptions from "../../utils/accreditation-body-options";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import FieldInfo from "../form/FieldInfo";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { Button, Modal } from "react-bootstrap";
import Map from "../form/Map";
import Form from "react-bootstrap/Form";


function FacilityDetails(props) {
  const {
    type,
    isMainDataSaved,
    setIsShortNameValid,
    areDocsSubmitted,
    hospitalid,
    fieldDisabled
  } = props;
  
  
  
  const ref = useRef();
  
  const formikProps = useFormikContext();
  
  const { mode } = useContext(ModeContext);
  const [accreditationBodyVal, setAccreditationBodyVal] = useState(formikProps?.values?.facilityDetails?.hosp_accreditation_by||null);
  const[registrationno, setRegistrationno] = useState(formikProps?.values?.facilityDetails?.hosp_registration_no||"");
  const[contactmail, setContactmail] = useState(formikProps?.values?.facilityDetails?.email||"");
  const[conno, setonno] = useState(formikProps?.values?.facilityDetails?.phone1||"");
  const[conno2, setConno2] = useState(formikProps?.values?.facilityDetails?.phone2||"");
  const[gstin, setGstin] = useState(formikProps?.values?.facilityDetails?.gstin||"");
  const[trade, settrade] = useState(formikProps?.values?.facilityDetails?.gstin_trade_name||"");
  const[legal, setLegal] = useState(formikProps?.values?.facilityDetails?.gstin_legal_name||"");

  const handleShortemailChange = (event) => {
    const email = event.target.value;
    setContactmail(email);
  
    // Set the value of the "shortName" field in formik context
    formikProps.setFieldValue("facilityDetails.email", email);
  };

  const handleregis = (event) =>{
   const registration = event.target.value;
   setRegistrationno(registration);
   formikProps.setFieldValue("facilityDetails.hosp_registration_no", registration);

  }

  const handlecontact1 = (event) =>{
  const contact1 = event.target.value;
  setonno(contact1);
  formikProps.setFieldValue("facilityDetails.phone1", contact1);

  }

  const handlecontact2 = (event) =>{
    const contact2 = event.target.value;
    setConno2(contact2);
    formikProps.setFieldValue("facilityDetails.phone2", contact2);
  
    }

    const handlegst = (event) =>{
       const gst = event.target.value;
       setGstin(gst);
       formikProps.setFieldValue("facilityDetails.gstin", gst);

    }

    const handlegal = (event) =>{
      const legal = event.target.value;
      setLegal(legal);
      formikProps.setFieldValue("facilityDetails.gstin_legal_name", legal);

    }

    const handletrade = (event) =>{
      const trade = event.target.value;
      settrade(trade);
      formikProps.setFieldValue("facilityDetails.gstin_trade_name", trade);

    }


  return (
    <>
        <Form className="mt-5" style={{caretColor: "none"}}>

      <div className="row" style={{ position: "relative" }}>
        <label className="accreditationBodyLabel px-0 pe-md-3 col-10 col-md-4 d-flex justify-content-start justify-content-md-end align-items-center">
          Accreditation By
        </label>

        <FieldInfo
          // info="In case of Individual Doctor Clinic, provide the Accreditation Authority name which issued Doctor’s Registration"
          classes="d-inline-block d-md-none text-end align-items-end m-0 p-0 h-100 w-auto"
        />

        <Field
          name="facilityDetails.hosp_accreditation_by"
          className="col-12 col-md-8"
        >
          {({ field, form }) => (
            <Creatable
              {...field}
              // isDisabled={isMainDataSaved }
              options={accreditationBodyOptions}
              name="facilityDetails.hosp_accreditation_by"
              placeholder="Accreditation Body"
              className="col-12 col-md-8 px-0 h-50"
              // value={accreditationBodyVal}
              onChange={(option) => {
                form.setFieldValue(
                  "facilityDetails.hosp_accreditation_by",
                  option,
                  true
                );
                setAccreditationBodyVal(option.value);
              }}
              styles={{
                control: (controlStyles) => ({
                  ...controlStyles,
                  padding: "0px",
                  border: "2px solid #b3c6e7",
                  borderRadius: "0",
                }),
              }}
            />
          )}
        </Field>

        {/* <FieldInfo
          // info="In case of Individual Doctor Clinic, provide the Accreditation Authority name which issued Doctor’s Registration"
          classes="d-none d-md-flex align-items-center m-0 p-0 h-100"
          styles={{ position: "absolute", right: "-50px", width: "auto" }}
        /> */}
      </div>

      {!accreditationBodyVal && (
        <ErrorMessage
          component={InputErrorMessage}
          name="facilityDetails.hosp_accreditation_by"
        />
      )}

      <FormControl
        // info="In case of Individual Doctor Clinic, provide the Doctor’s Registration No."
        label="Registration No"
        type="text"
        name="facilityDetails.hosp_registration_no"
        // value={registrationno}
        placeholder="Registration No"
        onChange={handleregis}
        // disabled={isMainDataSaved}
      />

      <FormControl
        // info="Email ID of the Facility Contact, this mail ID will be used for all communications"
        label="Contact Email"
        type="email"
        name="facilityDetails.email"
        placeholder="Contact Email"
        // value={contactmail}
        onChange={handleShortemailChange}
        // disabled={isMainDataSaved}
      />
      <FormControl
        // info="Phone no. of the Facility Contact, this Phone No. will be used for all important communications through SMS"
        label="Contact No. (1)"
        type="tel"
        name="facilityDetails.phone1"
        placeholder="Contact No. (1)"
        onChange={handlecontact1}
        // value={conno}
        // disabled={isMainDataSaved }
      />
    
        <FormControl
          // info="Second Phone no. of the Facility"
          label="Contact No. (2)"
          type="tel"
          name="facilityDetails.phone2"
          // disabled={isMainDataSaved }
          // value={conno2}
          onChange={handlecontact2}
          placeholder="Contact No. (2)"
          className="facility-contact-2"
        />
    
      
      <FormControl
        // info="GSTIN where Facility is located."
        label="GSTIN"
        type="text"
        name="facilityDetails.gstin"
        placeholder="GSTIN"
        // value={gstin}
        // disabled={isMainDataSaved}
        onChange={handlegst}
        className="facility-contact-2"
      />
      <FormControl
        // info="GST Legal Name where Facility is located."
        label="GST Legal Name"
        type="text"
        name="facilityDetails.gstin_legal_name"
        // disabled={isMainDataSaved }
        // value={legal}
        placeholder="GST Legal Name"
        className="facility-contact-2"
        onChange={handlegal}
      />
      <FormControl
        // info="GST Trade Name where Facility is located."
        label="GST Trade Name"
        type="text"
        name="facilityDetails.gstin_trade_name"
        // value={trade}
        // disabled={isMainDataSaved }
        onChange={handletrade}
        placeholder="GST Trade Name"
        className="facility-contact-2"
      />
      </Form>
    </>
  );
}

export default FacilityDetails;
