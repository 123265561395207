import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import HospitalsContext from "../../context/hospitals-context";
import ModeContext from "../../context/mode-context";
import Form from "../form/Form";
import DoctorDetails from "./DoctorDetails";
import DoctorSchedule from "./DoctorSchedule";
import RegistrationDocs from "./RegistrationDocs";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { rxOpdApi } from "../../utils/api/api";

const schedule_end_date = new Date();
schedule_end_date.setFullYear(schedule_end_date.getFullYear() + 1);

const scheduleInitialValues = {
  weekdays: "",
  slot_duration: "10",
  morning_start_time: "",
  morning_end_time: "",
  afternoon_start_time: "",
  afternoon_end_time: "",
  evening_start_time: "",
  evening_end_time: "",
  schedule_end_date: schedule_end_date,
};

const scheduleValidationSchema = Yup.object().shape({
  weekdays: Yup.array()
    .min(1, "Select at least one of the weekdays")
    .required("Weekdays is required"),
  slot_duration: Yup.string().required("Slot Duration is required"),
  schedule_end_date: Yup.date().required("End Date is required"),
});

const initialValues = {
  doctorDetails: {
    firstName: "",
    primary_specialization: "",
    lastName: "",
    gender: "",
    title:"",
    rmpNo: "",
    regYear: "",
    birthYear: "",
    degreeYear: "",
    accreditationby:"",
    qualification: "",
    speciality: "",
    consultCharges: "",
    onlineDiscount: "",
    email: "",
    phoneNo: "",
    showinmarketplace:""
  },

  onlineSchedule: scheduleInitialValues,

  inPersonSchedule: scheduleInitialValues,

  uploadDocs: {
    accreditationBody: "",
    rmpRegCertificate: "",
    docProfilePic: "",
  },
};

const validationSchema = Yup.object().shape({
  doctorDetails: Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    gender: Yup.mixed().required("Gender is required"),
    title: Yup.mixed().required("Title is required"),
    rmpNo: Yup.string().required("Registration No. is required"),
    regYear: Yup.string().required("Reg. Year is required"),
    birthYear: Yup.string().required("Birth Year is required"),
    degreeYear: Yup.string().required("Degree Year is required"),
    accreditationby: Yup.string().required("Accreditation By is required"),
    qualification: Yup.string().required("Qualification is required"),
    speciality: Yup.string().required("Specializations is required"),
    primary_specialization: Yup.mixed().required(
      "Primary Specialization is required"
    ),
    consultCharges: Yup.number()
      .required("Consult Charges is required")
      .min(1, "Consult Charges must be at least 1"),
    onlineDiscount: Yup.mixed().required("Online discount is required."),
    email: Yup.string().required("Email is required"),
    phoneNo: Yup.string().required("Phone No. is required"),
    

  }),

  onlineSchedule: scheduleValidationSchema,

  inPersonSchedule: scheduleValidationSchema,

  uploadDocs: Yup.object().shape({
    accreditationBody: Yup.object().required("Accrediation Body is required."),
    rmpRegCertificate: Yup.mixed().required(
      "RMP Registration Certificate is required."
    ),
    docProfilePic: Yup.mixed().required("Doctor's Profile Pic is required."),
  }),
});

function RegisterTab(props
) {
  const { mode } = useContext(ModeContext);
  const { currentHospital, isLoading } = useContext(HospitalsContext);

  const [formValues, setFormValues] = useState(null);
  const [dirty, setDirty] = useState(false);

  const [formNavItems, setFormNavItems] = useState([
    {
      label: "Doctor's Details",
      identifier: "doctorDetails",
      isActive: true,
      element: DoctorDetails,
      elementWrapperClassName: "",
      formHeading:
        "Provide doctor’s details. User RMP Search to fetch NMC RMP (Registered Medical Professional) Details.",
      elementProps: { setDirty },
    },
    {
      label: "Online Schedule",
      identifier: "onlineSchedule",
      isActive: false,
      // element: <DoctorSchedule type="onlineSchedule" />,
      element: DoctorSchedule,
      elementProps: { type: "onlineSchedule" },
      elementWrapperClassName: "",
      formHeading:
        "Create a recurring schedule (for a Time Period) for doctor for Online Consultation.",
    },
    {
      label: "In-Person Schedule",
      identifier: "inPersonSchedule",
      isActive: false,
      // element: <DoctorSchedule type="inPersonSchedule" />,
      element: DoctorSchedule,
      elementProps: { type: "inPersonSchedule" },
      elementWrapperClassName: "",
      formHeading:
        "Create a recurring schedule (for a Time Period) for doctor for In-Person (at Facility) Consultation.",
    },
    {
      label: "Upload Documents & Submit Form",
      isActive: false,
      // element: <RegistrationDocs />,
      element: RegistrationDocs,
      // elementWrapperClassName: "w-75",
      formHeading:
        "Upload required documents and submit to complete Doctor's registration.",
      // elementProps: {},
    },
  ]);
  const [doctorId, setDoctorId] = useState(null);
  const [isDocInitialValLoading, setIsDocInitialValLoading] = useState(true);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  // console.log("isEditModeOn", isEditModeOn);
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const toBeEditedDocId = searchParams.get("doc_id");

  useEffect(() => {
    if (toBeEditedDocId)
      if (dirty === true) {
        setIsEditModeOn(false);
      } else {
        setIsEditModeOn(true);
      }
  }, [dirty]);
  useEffect(() => {
    if (searchParams.get("edit")) {
      const fetchDoctorData = async () => {
        const userKeys = localStorage.getItem("usr_keys");
        const userModeKey = JSON.parse(userKeys)[mode];

        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        try {
          rxOpdApi.setAuthHeaders(key, secret);
          const res = await rxOpdApi.get(
            RX_OPD_ENDPOINTS.HOSPITAL.FETCH_A_DOCTOR +
              "/" +
              currentHospital.hos_id +
              "/" +
              toBeEditedDocId
          );
          const onlineScheduleRes = await rxOpdApi.get(
            RX_OPD_ENDPOINTS.HOSPITAL.FETCH_DOCTOR_RECURRING_SCHEDULE +
              "/" +
              currentHospital.hos_id +
              "/" +
              toBeEditedDocId +
              "/online"
          );
          const inPersonScheduleRes = await rxOpdApi.get(
            RX_OPD_ENDPOINTS.HOSPITAL.FETCH_DOCTOR_RECURRING_SCHEDULE +
              "/" +
              currentHospital.hos_id +
              "/" +
              toBeEditedDocId +
              "/in-person"
          );

          if (res && onlineScheduleRes && inPersonScheduleRes) {
            const toBeEditedDocData = res.data;
            // console.log('27272222', toBeEditedDocData )
                        const toBeEditedOnlineSchedule =
              Object.keys(onlineScheduleRes.data).length > 0
                ? {
                    weekdays: onlineScheduleRes.data.weekdays.map((day) =>
                      day.toString()
                    ),
                    morning_start_time: onlineScheduleRes.data.morning_end_time,
                    morning_end_time: onlineScheduleRes.data.morning_end_time,
                    afternoon_start_time:
                      onlineScheduleRes.data.afternoon_start_time,
                    afternoon_end_time:
                      onlineScheduleRes.data.afternoon_end_time,
                    evening_start_time:
                      onlineScheduleRes.data.evening_start_time,
                    evening_end_time: onlineScheduleRes.data.evening_end_time,
                    slot_duration: onlineScheduleRes.data.slot_duration,
                    schedule_end_date: new Date(
                      onlineScheduleRes.data.schedule_end_date
                    ),
                  }
                : scheduleInitialValues;
            const toBeEditedInPersonSchedule =
              Object.keys(inPersonScheduleRes.data).length > 0
                ? {
                    weekdays: inPersonScheduleRes.data.weekdays.map((day) =>
                      day.toString()
                    ),
                    morning_start_time:
                      inPersonScheduleRes.data.morning_end_time,
                    morning_end_time: inPersonScheduleRes.data.morning_end_time,
                    afternoon_start_time:
                      inPersonScheduleRes.data.afternoon_start_time,
                    afternoon_end_time:
                      inPersonScheduleRes.data.afternoon_end_time,
                    evening_start_time:
                      inPersonScheduleRes.data.evening_start_time,
                    evening_end_time: inPersonScheduleRes.data.evening_end_time,
                    slot_duration: inPersonScheduleRes.data.slot_duration,
                    schedule_end_date: new Date(
                      inPersonScheduleRes.data.schedule_end_date
                    ),
                  }
                : scheduleInitialValues;

            setFormValues({
              doctorDetails: {
                firstName: toBeEditedDocData.firstname,
                lastName: toBeEditedDocData.lastname,
                primary_specialization: {
                  label: toBeEditedDocData.primary_specialization,
                  value: toBeEditedDocData.primary_specialization,
                },
                gender: {
                  label: toBeEditedDocData.gender,
                  value: toBeEditedDocData.gender,
                },
                title: {
                  label: toBeEditedDocData.title,
                  value: toBeEditedDocData.title,
                },
                rmpNo: toBeEditedDocData.rmp_num,
                regYear: toBeEditedDocData.rmp_reg_year,
                birthYear: toBeEditedDocData.rmp_birth_year,
                degreeYear: toBeEditedDocData.rmp_degree_year,
                accreditationby: toBeEditedDocData.rmp_accred_by,
                qualification: toBeEditedDocData.qualification,
                speciality: toBeEditedDocData.speciality,
                consultCharges: toBeEditedDocData.consult_charge,
                onlineDiscount: {
                  label: toBeEditedDocData.online_discount,
                  value: toBeEditedDocData.online_discount,
                },
                
                email: toBeEditedDocData.email,
                phoneNo: toBeEditedDocData.phone.replace("+91", ""),
                showinmarketplace: {
                  label: toBeEditedDocData.marketplace_flag,
                  value: toBeEditedDocData.marketplace_flag,
                }

              },

              onlineSchedule: toBeEditedOnlineSchedule,

              inPersonSchedule: toBeEditedInPersonSchedule,

              uploadDocs: {
                accreditationBody: "",
                rmpRegCertificate: "",
                docProfilePic: "",
              },
            });
            setDoctorId(toBeEditedDocId);
          } else {
            throw new Error("Something went wrong. Please try later.");
          }
        } catch (error) {
          setShowToast(true);
          setToastType("error");
          setToastMessage(error.message);
          setErrorOccurred(true);
        } finally {
          setIsDocInitialValLoading(false);
        }
      };

      fetchDoctorData();
    } else {
      setFormValues({
        doctorDetails: {
          firstName: "",
          lastName: "",
          gender: "",
          title: "",
          rmpNo: "",
          regYear: "",
          birthYear: "",
          degreeYear: "",
          accreditationby: "",
          country: "",
          state: "",
          city: "",
          qualification: "",
          speciality: "",
          primary_specialization: "",
          consultCharges: "",
          onlineDiscount: {
            label: "0",
            value: "0",
          },

          email: "",
          phoneNo: "",
          showinmarketplace:""
        },

        onlineSchedule: scheduleInitialValues,

        inPersonSchedule: scheduleInitialValues,

        uploadDocs: {
          accreditationBody: "",
          rmpRegCertificate: "",
          docProfilePic: "",
        },
      });
      setIsDocInitialValLoading(false);
    }
  }, []);
  

  const handleSaveData = async (values, itemNumber) => {
    const userKeys = localStorage.getItem("usr_keys");

    if (userKeys && !isLoading) {
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      let endPoint;
      let body;
      let res;

      try {
        rxOpdApi.setAuthHeaders(key, secret);

        switch (itemNumber) {
          case 1:
            body = {
              email: toBeEditedDocId ? undefined : values.email,
              phone: toBeEditedDocId ? undefined : "+91" + values.phoneNo,
              firstname: values.firstName,
              gender: values.gender.value,
              title:values.title.value,
              lastname: values.lastName,
              qualification: values.qualification,
              speciality: values.speciality,
              primary_specialization: values.primary_specialization.value,
              rmp_accred_by: currentHospital.hosp_accreditation_by,
              rmp_reg_year: values.regYear,
              rmp_birth_year: values.birthYear,
              rmp_degree_year: values.degreeYear,
              rmp_accred_by:values.accreditationby,
              rmp_num: values.rmpNo,
              consult_charge: values.consultCharges,
              currency: "INR",
              online_discount: +values.onlineDiscount.value,
              marketplace_flag: values.showinmarketplace,


            };
            if (toBeEditedDocId) {
              endPoint =
                RX_OPD_ENDPOINTS.HOSPITAL.UPLOAD_DOCTOR_DETAILS +
                "/" +
                currentHospital.hos_id +
                "/" +
                toBeEditedDocId;
              res = await rxOpdApi.put(endPoint, body);
            } else {
              endPoint =
                RX_OPD_ENDPOINTS.HOSPITAL.REGISTER_DOCTOR +
                "/" +
                currentHospital.hos_id;
              res = await rxOpdApi.post(endPoint, body);
            }

            setDoctorId(res.data.doc_id);
            break;

          case 2:
          case 3:
            endPoint =
              RX_OPD_ENDPOINTS.HOSPITAL.CREATE_DOCTOR_RECURRING_SCHEDULE +
              "/" +
              currentHospital.hos_id +
              "/" +
              doctorId;
            body = {
              appointment_type: itemNumber === 2 ? "online" : "in-person",
              days_list: values.weekdays.map((val) => +val),
              morning_start_time: values.morning_start_time
                ? values.morning_start_time
                : undefined,
              morning_end_time: values.morning_end_time
                ? values.morning_end_time
                : undefined,
              afternoon_start_time: values.afternoon_start_time
                ? values.afternoon_start_time
                : undefined,
              afternoon_end_time: values.afternoon_end_time
                ? values.afternoon_end_time
                : undefined,
              evening_start_time: values.evening_start_time
                ? values.evening_start_time
                : undefined,
              evening_end_time: values.evening_end_time
                ? values.evening_end_time
                : undefined,
              slot_duration: values.slot_duration,
              schedule_end_date: `${values.schedule_end_date.getFullYear()}-${
                values.schedule_end_date.getMonth() + 1
              }-${values.schedule_end_date.getDate()}`,
            };
            res = await rxOpdApi.put(endPoint, body);
            break;
        }

        return { success: true, message: res.data.message };
      } catch (error) {
        return { success: false, message: error.message };
      }
    }
  };

  const handleFormSubmit = async (values) => {
    if (!isLoading) {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        const doctorRegistrationDocsFormData = new FormData();
        doctorRegistrationDocsFormData.append(
          "registration_certificate",
          values.uploadDocs.rmpRegCertificate
        );
        doctorRegistrationDocsFormData.append(
          "profile_pic",
          values.uploadDocs.docProfilePic
        );

        rxOpdApi.setMultipartHeaders();
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.post(
          `${RX_OPD_ENDPOINTS.HOSPITAL.UPLOAD_DOCTOR_DOCS}/${
            currentHospital.hos_id
          }/${doctorId}/${values.uploadDocs.accreditationBody.value.toUpperCase()}`,
          doctorRegistrationDocsFormData
        );

        if (res) {
          return { success: true, message: res.data.message };
        } else {
          throw new Error("Something went wrong. Please try later.");
        }
      } catch (error) {
        return { success: false, message: error.message };
      }
    }
  };

  return (
    <Form
      type="Doctor's Registration"
      initialValues={formValues}
      validationSchema={validationSchema}
      formNavItems={formNavItems}
      setFormNavItems={setFormNavItems}
      onSaveBtnClick={handleSaveData}
      onSubmit={handleFormSubmit}
      isLoading={isLoading || isDocInitialValLoading}
      areDocsSubmitted={isEditModeOn ? isEditModeOn : false}
      redirectUrl="/app/doctors/doctors"
      fieldDisabled={toBeEditedDocId}
    />
  );
}

export default RegisterTab;
